import {
  CONSOLIDATION_TYPES,
  ORDERS_CONSOLIDATION_TYPE,
  CONSOLIDATION_TYPES_FROM_YOUR_CITY_ARRAY,
  SHIPPING_COST_CALC_WAREHOUSES
} from "../../staticData/staticVariables";

export const consolidationMixinHelper = {

  data() {
    return {
      CONSOLIDATION_TYPES: CONSOLIDATION_TYPES,
      ORDERS_CONSOLIDATION_TYPE: ORDERS_CONSOLIDATION_TYPE,
      CONSOLIDATION_TYPES_FROM_YOUR_CITY_ARRAY: CONSOLIDATION_TYPES_FROM_YOUR_CITY_ARRAY,
    }
  },

  computed: {
    isSkladLodz() {
      return SHIPPING_COST_CALC_WAREHOUSES.SHIPPING_COST_CALC_WAREHOUSE_POLAND.id === this.consolidation.data.warehouse?.id
    },

    isDHL_USA_BY_DESTINATION_TYPE() {
      return this.consolidation.getConsolidationDestination()?.id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL.id
    },

    isFedex() {
      // return this.$route.params.type === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX.name
      return this.$route.params.type === ORDERS_CONSOLIDATION_TYPE.FEDEX
    },

    isDHL_USA() {
      return this.$route.params.type === ORDERS_CONSOLIDATION_TYPE.DHL_USA
    },

    isTNT() {
      return this.$route.params.type === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_TNT.name
    },

    isDHL() {
      return this.$route.params.type === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL.name
    },

    isDPD() {
      // return this.$route.params.type === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DPD.name
      return this.$route.params.type === ORDERS_CONSOLIDATION_TYPE.DPD
    },

    isEUProduct() {
      return this.$route.params.type === ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT
    },

    isFromYourCity() {
      return this.$route.params.type === ORDERS_CONSOLIDATION_TYPE.FROM_YOUR_CITY
    },

    isFromYourCityDestination() {
      return CONSOLIDATION_TYPES_FROM_YOUR_CITY_ARRAY.findIndex(item => {
        return +item.id === +this.consolidation.getConsolidationDestination()?.id
      }) !== -1
    },

    isOwnCarrier() {
      return +this.consolidation.getConsolidationDestination()?.id === +CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_OWN_CARRIER.id
    },

    isFromAPI() {
      return this.consolidation.getConsolidationDestination()?.api === 1
    },

    hasWarehouses() {
      let warehouses = this.consolidation.getConsolidationDestination()?.warehouses
      if(warehouses && !this.isFromYourCity) {
        return warehouses.length > 0
      }
      return false
    },

    checkVisiblePromoCode() {
      return this.responseData?.promocodes_available
    }
  },

  methods: {
    getVatCost(withCells = false) {
      let cost = 0
      let vatCommissionPercent = this.isEUProduct ? this.$store.getters.GET_COMMON_AUTHORIZED?.product_consolidation_vat : this.$store.getters.GET_COMMON_AUTHORIZED?.eu_consolidation_vat
      this.consolidation.data.productsForCellArray.map(item => {
        let productCount = withCells ? item.data.productCount : item.data.ProformProduct.product.itemsQuantity
        let totalProductCost = Number(item.data.ProformProduct.product.price) * Number(productCount)
        cost = cost + totalProductCost * (Number(vatCommissionPercent) / 100)
      })
      return parseFloat(cost).toFixed(2)
    },

    getConsolidationType(item) {
      if (item.consolidation_destination.id === this.CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id ||
          item.consolidation_destination.id === this.CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id ||
          item.consolidation_destination.id === this.CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_OWN_CARRIER.id) {
        return this.ORDERS_CONSOLIDATION_TYPE.FROM_YOUR_CITY
      }
      if (item.consolidation_destination.delivery_service_id === this.CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL.delivery_service_id) {
        return this.ORDERS_CONSOLIDATION_TYPE.DHL
      }
      if (item.consolidation_destination.delivery_service_id === this.CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_TNT.delivery_service_id) {
        return this.ORDERS_CONSOLIDATION_TYPE.TNT
      }
      if (item.consolidation_destination.delivery_service_id === this.CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX.delivery_service_id) {
        return this.ORDERS_CONSOLIDATION_TYPE.FEDEX
      }
      if (item.consolidation_destination.delivery_service_id === this.CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DPD.delivery_service_id) {
        return this.ORDERS_CONSOLIDATION_TYPE.DPD
      }
      if (item.consolidation_destination.delivery_service_id === this.CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_EU_PRODUCT.delivery_service_id) {
        return this.ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT
      }
    },

    getConsolidationTypeByDestinationId(destinationId) {
      if(!destinationId) return false

      if (+destinationId === this.CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id ||
          +destinationId === this.CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id ||
          +destinationId === this.CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_OWN_CARRIER.id) {
        return this.ORDERS_CONSOLIDATION_TYPE.FROM_YOUR_CITY
      }
      if (+destinationId === this.CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL.id) {
        return this.ORDERS_CONSOLIDATION_TYPE.DHL_USA
      }
      if (+destinationId === this.CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_TNT.id) {
        return this.ORDERS_CONSOLIDATION_TYPE.TNT
      }
      if (+destinationId === this.CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX.id) {
        return this.ORDERS_CONSOLIDATION_TYPE.FEDEX
      }
      if (+destinationId === this.CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DPD.id) {
        return this.ORDERS_CONSOLIDATION_TYPE.DPD
      }
      if (+destinationId === this.CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_EU_PRODUCT.id) {
        return this.ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT
      }
    },
  }
}