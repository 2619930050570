import {consolidationMixinHelper} from "./consolidationMixinHelper";
import {
	DHL_DELIVERY_SERVICE,
	FEDEX_DELIVERY_SERVICE,
	TNT_DELIVERY_SERVICE,
	DPD_DELIVERY_SERVICE,
	EU_PRODUCT_DELIVERY_SERVICE,
	CONSOLIDATION_STATUSES,
	NOVAPOSHTA_DELIVERY_SERVICE,
	INTERNAL_STATUSES,
	ORDERS_CONSOLIDATION_TYPE,
	PROFORM_TYPE_NAMING, CONSOLIDATION_TYPES,
} from "../../staticData/staticVariables";
import {ProductCells} from "../../components/globalModels/ProductCells";
const ServiceTypes = {
	DHL_DELIVERY_SERVICE,
	FEDEX_DELIVERY_SERVICE,
	TNT_DELIVERY_SERVICE,
	DPD_DELIVERY_SERVICE,
	EU_PRODUCT_DELIVERY_SERVICE,
}

export const consolidationMixin = {
	mixins: [consolidationMixinHelper],

	methods: {
		isFromYourCityType(item) {
			return (
				item.consolidation_destination_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id ||
				item.consolidation_destination_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id
			)
		},

		stopOrderConsolidation(item) {
			this.$store.dispatch('consolidationStopOrder', item?.id).then(response => {
				switch (response.status) {
					/**
					 * Success
					 */
					case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
						this.$emit('reload')
						this.openNotify('success', 'common_notificationRecordChanged')
						break
					}
					/**
					 * Validation Error
					 */
					case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
						let errors = response.response.data.errors;
						this.notifyErrorHelper(errors)
						break
					}
					/**
					 * Undefined Error
					 */
					default: {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				}
			})
		},

		changeConsolidationProductInCells() {
			// if (this.consolidation.getOldStatus()?.value === CONSOLIDATION_STATUSES.CONSOLIDATION_STATUS_COUNTED.value ||
			// 	this.consolidation.getOldStatus()?.value === CONSOLIDATION_STATUSES.CONSOLIDATION_STATUS_RECEIVED.value) {
			// 	return
			// }
			// let prepareSaveData = this.consolidation.prepareSave(false, true, true)
			// let data = {
			// 	product_data: prepareSaveData['product_data']
			// }
			//
			// this.$store.dispatch('updateConsolidationProductInCells', {id: this.consolidation.data.id, data}).then(response => {
			// 	switch (response.status) {
			// 		/**
			// 		 * Success
			// 		 */
			// 		case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
			// 			break
			// 		}
			// 		/**
			// 		 * Validation Error
			// 		 */
			// 		case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
			// 			let errors = response.response.data.errors;
			// 			this.notifyErrorHelper(errors)
			// 			break
			// 		}
			// 		/**
			// 		 * Undefined Error
			// 		 */
			// 		default: {
			// 			this.openNotify('error', 'common_notificationUndefinedError')
			// 		}
			// 	}
			// })
		},

		thermalPrint(data) {

			if (!data.id) return

			let routeData = this.$router.resolve({
				path: this.$store.getters.GET_PATHS.generatePdfFile + '/consolidation-thermal-print',
				query: {
					id: data.id,
					type: 'pdf'
				}
			})

			window.open(routeData.href, '_blank');

		},

		checkPermission(){
			// return this.checkOrderTypePermission({
			// 	types: {
			//
			// 	},
			// 	currentType: this.$route.params.type
			// })
			return true
		},

		prepareSaveConsolidation({draft = false, edit = false, createLabel = false}) {

			if(this.isAdmin) {
				let receivedAtFlag = false
				if (this.consolidation.getStatus()?.value === CONSOLIDATION_STATUSES.CONSOLIDATION_STATUS_COUNTED.value ||
					this.consolidation.getStatus()?.value === CONSOLIDATION_STATUSES.CONSOLIDATION_STATUS_RECEIVED.value) {
					receivedAtFlag = true
				}
				if (!this.consolidation.ConsolidationAdminValidation(
					{
						userValidate: true,
						consolidationDestination: true,
						shippedAt: true,
						warehouse: this.hasWarehouses,
						deliveryService: !this.isFromAPI,
						deliveryDate: this.isTNT,
						dimensions: true,
						// relatedOrders: true,
						relatedOrders: false,
						senderContactName: this.isFromAPI && !this.isOwnCarrier &&
							this.consolidation.data.sendType === this.consolidation.data.sendTypes.sender,
						status: true,
						receivedAt: receivedAtFlag,
						consolidationUnion: edit,
					}
				)) {
					return
				}

				if (this.consolidation.getStatus()?.value === CONSOLIDATION_STATUSES.CONSOLIDATION_STATUS_COUNTED.value &&
					!this.consolidation.ConsolidationCellCountsValidate(edit)) {
					this.openNotify('error', 'common_ChooseProductCell')
					return
				}

				if (edit && !this.consolidation.ConsolidationScannedInKievValidate()) {
					this.openNotify('error', 'common_ChooseScannedInKiev')
					return
				}
			}

			let data = this.consolidation.prepareSave(draft, this.isAdmin, edit)

			if(draft){
				let confirm = () => {
					this.saveConsolidation(edit, data).then(() => {
						// this.successSave(status)
					})

					this.$store.commit('setSaveInDraftsPopup', false)
					return true
				}

				this.saveInDraftsFunc(confirm)
				return
			}

			if(!draft){
				this.saveConsolidation(edit, data, createLabel).then(() => {
					if(this.isAdmin && edit) {
						this.setCheckedOrders()
					}
					// if(createLabel){
					// 	this.createLabel(response.id, adminManage).then(status => {
					// 		if(status){
					// 			this.successSave(status, createLabel, response.id)
					// 		}
					// 	})
					// 	return
					// }
					// this.successSave(response.status)
				})
			}

		},

		setCheckedOrders() {
			let orderCheckedData = []
			this.consolidation.data.relatedOrders.map(order => {
				orderCheckedData.push({
					id: order.id,
					status: order.orderChecked ? 1 : 0
				})
			})

			this.$store.dispatch('updateConsolidationOrderCheck', {data: {orders: orderCheckedData}, id: this.consolidation.data.id}).then(response => {
				if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

				switch (this.getResponseStatus(response)) {
					/**
					 * Success
					 */
					case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
						break
					}
					/**
					 * Validation Error
					 */
					case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
						let errors = response.response.data.errors;
						this.notifyErrorHelper(errors)
						break
					}
					/**
					 * Undefined Error
					 */
					default: {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				}
			})
		},

		saveConsolidation(edit = false, data, createLabel = false) {

			let saveType = 'createConsolidation'

			if (edit) {
				data = {
					id: this.consolidation.getId(),
					data: data
				}
				saveType = 'updateConsolidation'
			}

			console.log(data);
			console.log(saveType);

			return this.$store.dispatch(saveType, data).then(response => {
				if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')
				console.log(data);

				switch (this.getResponseStatus(response)) {
					/**
					 * Success
					 */
					case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

						let consoId = response.data?.data?.consolidation?.id
						if (!edit) {
							this.createdConsoId = response.data?.data?.consolidation?.id
						}

						if (edit && this.isAdmin) {
							this.openNotify('success', 'common_notificationRecordChanged')
							if (createLabel) {
								this.makeLabelHandler(consoId).then((status) => {
									if(this._.has(status, 'statusError')){
										return
									}
									if(!this._.has(status, 'statusPaid')){
										this.reloadOrder()
									}
								}).catch(error => this.createErrorLog(error))
							}
							else {
								this.reloadOrder()
							}
						}
						else if (edit && !this.isAdmin) {
							if (createLabel) {
								this.makeLabelHandler(consoId).then((status) => {
									if(this._.has(status, 'statusError')){
										return
									}
									if(!this._.has(status, 'statusPaid')){
										this.openNotify('success', 'common_notificationRecordChanged')
										this.$router.push(this.$store.getters.GET_PATHS.ordersConsolidation)
									}
								}).catch(error => this.createErrorLog(error))
							}
							else {
								this.openNotify('success', 'common_notificationRecordChanged')
								this.$router.push(this.$store.getters.GET_PATHS.ordersConsolidation)
							}

						} else {
							if (createLabel) {
								this.makeLabelHandler(consoId).then((status) => {
									if(this._.has(status, 'statusError')){
										return
									}
									if(!this._.has(status, 'statusPaid')){
										this.openNotify('success', 'common_notificationRecordCreated')
										this.$router.push(this.$store.getters.GET_PATHS.ordersConsolidation)
									}
								}).catch(error => this.createErrorLog(error))
							}
							else {
								this.openNotify('success', 'common_notificationRecordCreated')
								this.$router.push(this.$store.getters.GET_PATHS.ordersConsolidation)
							}
						}

						break
					}
					/**
					 * Validation Error
					 */
					case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
						let errors = response.response.data.errors;
						this.notifyErrorHelper(errors)
						break
					}
					/**
					 * Undefined Error
					 */
					default: {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				}
			})

		},

		nextPageHandler() {

			let valid = true

			switch (this.activeStep) {
				case 1:
					valid = this.consolidation.stepAValidation({
						userValidate: this.isAdmin,
						consolidationDestination: true,
						shippedAt: true,
						warehouse: this.hasWarehouses,
						deliveryService: !this.isFromAPI && !((this.isBaltic && !this.isAdmin) ||
							(this.isAdmin && this.isBalticByUserData(this.consolidation.data.User?.user?.user_personal_contact?.country_group))),
						deliveryDate: this.isTNT,
						dimensions: this.isTNT,
						// relatedOrders: this.isTNT,
						relatedOrders: false,
					})
					break
				case 2:
					valid = this.consolidation.stepBValidation({
						dimensions: !this.isTNT,
						// relatedOrders: !this.isTNT,
						relatedOrders: false,
					})
					break
				case 3:
					valid = this.consolidation.stepCValidation({senderContactName: this.isTNT}, this)
					break
				case 4:
					valid = this.consolidation.stepDValidation({
						senderContactName: !this.isTNT && this.isFromAPI && !this.isOwnCarrier &&
							this.consolidation.data.sendType === this.consolidation.data.sendTypes.sender,
					})
					break
			}

			if (valid)
				if (this.activeStep < this.progressStepsSetting.length) this.activeStep = this.activeStep + 1
		},

		getConsolidationTypeById(id) {
			let result = this._.clone(this._.find(ServiceTypes, {id: parseInt(id)}))

			if (result?.name === ServiceTypes.FEDEX_DELIVERY_SERVICE.name) {
				result.name = ORDERS_CONSOLIDATION_TYPE.FEDEX
			}
			if (result?.name === ServiceTypes.DPD_DELIVERY_SERVICE.name) {
				result.name = ORDERS_CONSOLIDATION_TYPE.DPD
			}
			if (result?.name === ServiceTypes.EU_PRODUCT_DELIVERY_SERVICE.name) {
				result.name = ORDERS_CONSOLIDATION_TYPE.EU_PRODUCT
			}

			// default (fix for, not supported in current released)
			if(!result) {
				result = {}
				result['name'] = ORDERS_CONSOLIDATION_TYPE.FEDEX
			}

			return result
		},

		initProform(itemProduct, id) {
			return this.$store.dispatch('getProducts', id + `?storageId=${this.consolidation.data.orderWarehouse?.id}`).then((response) => {
				let data = this.getRespData(response)

				if (!data?.proformData?.proformOptions) {
					data.proformData.proform_category = ''
					data.proformData.proformOptions = {}
					data.proformData.proformOptions.handmade = '1'
					data.proformData.proformOptions.item_price = 0
					data.proformData.proformOptions.item_quantity = 0
				}

				// if (data.proformData !== [] && data?.count_in_storage?.count_normal === 0) {
				// 	this.openNotify('warn', 'fbm_ProductMissingWarning')
				// }

				itemProduct.setProductItem(data, null, this)

				this.initialProformSelectOptions({
					val: itemProduct.product.proformData.proformCategory,
					item: itemProduct
				}).then(() => {
					itemProduct.copyStartProfile()
				}).catch(error => this.createErrorLog(error))
			}).catch(error => this.createErrorLog(error))

		},

		changeProductObject(item, val, productForCell = false) {
			console.log('ssssssssss',item,val, productForCell);
			if(val === ''){
				item.unsetProductProformType({})
				item.setProductProformDataCategory('')
				item.setProductObject('')
				item.setProductName('')
			} else {

				if(productForCell !== false){
					this.setCellsInProductForCellInbound(val, productForCell)
					// productForCell.data.Product.setProduct(val)
					// this.setCellsInProductForCell(val, productForCell)

				}
				return this.initProform(item, val.id)
			}
		},

		setCellsInProductForCellInbound(val, productForCell){
			let currentOrder = this.$store.getters.getConsolidationItem
			let userProductCells = []

			productForCell.data.UserMixedProductCellArray = []
			productForCell.data.UserProductCellArray = []

			val.items_in_storage.map(itemInStorage => {
				let productCells = new ProductCells(false, itemInStorage.cell, itemInStorage.quantity, false)
				if(itemInStorage?.cell?.mixed){
					productForCell.data.UserMixedProductCellArray.push(productCells)
				} else {
					productForCell.data.UserProductCellArray.push(productCells)
				}
				userProductCells.push(productCells)
			})

			let freeCells = [...this.consolidation.addOtherFreeCells(currentOrder)]

			let mixedFreeCells = [...this.consolidation.addMixedFreeCells(currentOrder)]

			let userCells = [...this.consolidation.addOtherUserCells(currentOrder, true, userProductCells)]

			productForCell.data.FreeCellArray = freeCells
			productForCell.data.MixedCellArray = mixedFreeCells
			productForCell.data.UserCellArray = userCells

			/**
			 * sort by cell count
			 */
			let userArray = productForCell.data.UserProductCellArray
			let userMixedProductCellArray = productForCell.data.UserMixedProductCellArray

			productForCell.data.UserProductCellArray = this.consolidation.sortCellArrayByStorageCount(userArray)
			productForCell.data.UserMixedProductCellArray = this.consolidation.sortCellArrayByStorageCount(userMixedProductCellArray)

		},

		addProformProduct(array = false) {
			let newIndex = this.consolidation.addProformProduct(array)
			this.initializeProductProformArray(array, newIndex)
		},

		removeProformProduct(array, index) {
			this.ordersInbound.removeProformProduct(array, index)
		},

		getDefaultWarehouse(val) {

			// get Warehouse for Ship to warehouse field
			let warehouseCodeId = val

			const query = new this.Query();
			let url = '?';
			let myQuery = query
				.for('posts')

			myQuery.where('warehouseCode', warehouseCodeId)

			url = url + myQuery.url().split('?')[1]
			this.$store.dispatch('fetchWarehouses', url).then(() => {
				if(this.$store.getters.getWarehouses.length > 0) {
					this.consolidation.setWarehouse(this.$store.getters.getWarehouses[0]);
				}
			})
		},

		/**
		 * Products components for user and Products & Shipping Details for admin
		 * @param item
		 * @param val
		 */
		changeProductName(item, val) {
			item.setProduct(val)
		},
		changeProductQuantity(item, val) {
			item.setProductCount(val)
		},

		isInternalNovaPoshta(item) {
			return (item && item?.order_internal?.delivery_service_id === NOVAPOSHTA_DELIVERY_SERVICE.id)
		},

		isInternalLabelCreated(item) {
			return (item && INTERNAL_STATUSES.COMPLETE.id === item?.order_internal_status_id)
		},

		isInternalDraft(item) {
			return (item && INTERNAL_STATUSES.IN_PROGRESS.id === item?.order_internal_status_id && !item.tracking_number)
		},

		printLabels() {
			let ids = this.consolidation.getRelatedOrders().map(item => {
				return item.id
			})

			let format = 'docx'
			this.$store.dispatch('getOrderFBMThermalPrint', {ids: ids, format: format}).then((response) => {
				this.globalDownloadBase64File('application/docx', response.data.data.fileContentBase64, response.data.data.fileName)
			})
		},

		downloadInnerLabels(id) {
			let routeData = this.$router.resolve({
				path: this.$store.getters.GET_PATHS.generatePdfFile + '/consolidation-inner-labels',
				query: {
					id: id,
					type: 'pdf'
				}
			})

			window.open(routeData.href, '_blank');
		},

		generateCreateLabelDataFromCard() {
			// let senderData = {
			// 	senderContactName: this.consolidation.getSenderContactName(),
			// 	senderPhone: this.consolidation.data.User?.user?.user_personal_contact?.phone,
			// }

			let products = []
			this.consolidation.data.proformProductsForTable.forEach(item => {
				if (this._.has(item.product.proformData.proformTypes[PROFORM_TYPE_NAMING].typeValue, 'value')) {
					products.push({
						name: item.product.proformData.proformTypes[PROFORM_TYPE_NAMING].typeValue.value.translationStorage.en.name,
						count: item.product.itemsQuantity,
						price: item.product.price,
					})
				}
			})

			this.consolidation.data.productsForCellArray.forEach(item => {
				if (this._.has(item.data.ProformProduct.product.proformData.proformTypes[PROFORM_TYPE_NAMING].typeValue, 'value')) {
					products.push({
						name: item.data.ProformProduct.product.proformData.proformTypes[PROFORM_TYPE_NAMING].typeValue.value.translationStorage.en.name,
						count: item.data.ProformProduct.product.itemsQuantity,
						price: item.data.ProformProduct.product.price,
					})
				}
			})

			let data = {
				"for_consolidation": true,
				"user_id": this.consolidation.data.User?.user?.id,
				"warehouse_id" : this.consolidation.data.sendType === this.consolidation.data.sendTypes.warehouse ? this.consolidation.getWarehouse()?.id : null,
				"sender":  this.consolidation.data.sendType === this.consolidation.data.sendTypes.warehouse ? {
						"country_code": this.consolidation.getWarehouse().country?.code_iso_2,
						"post_code": this.consolidation.getWarehouse()?.sender_postcode,
					} : this.consolidation.data.User.user?.use_alternative_address === 1 ?
					{
						"country_code": this.consolidation.data.User.user?.user_alternative_contact ? this.consolidation.data.User.user.user_alternative_contact.country.code_iso_2 :
							this.consolidation.data.User.user.contacts[1].country.code_iso_2,
						"city": this.consolidation.data.User.user?.user_alternative_contact ? this.consolidation.data.User.user.user_alternative_contact.city :
							this.consolidation.data.User.user.contacts[1].city,
						"post_code": this.consolidation.data.User.user?.user_alternative_contact ? this.consolidation.data.User.user.user_alternative_contact.zip :
							this.consolidation.data.User.user.contacts[1].zip,
					} :
					{
						"country_code": this.consolidation.data.User.user?.user_personal_contact ? this.consolidation.data.User.user.user_personal_contact.country.code_iso_2 :
							this.consolidation.data.User.user.contacts[0].country.code_iso_2,
						"city": this.consolidation.data.User.user?.user_personal_contact ? this.consolidation.data.User.user.user_personal_contact.city :
							this.consolidation.data.User.user.contacts[0].city,
						"post_code": this.consolidation.data.User.user?.user_personal_contact ? this.consolidation.data.User.user.user_personal_contact.zip :
							this.consolidation.data.User.user.contacts[0].zip,
					},
				"recipient": {
					"country_code": this.consolidation.data.recipientWarehouse?.country?.code_iso_2,
					"post_code": this.consolidation.data.recipientWarehouse?.sender_postcode,

				},
				"products": products,
				"proform_packaging": {
					"weight": this.consolidation.data.Dimensions.data.weight,
					"length": this.consolidation.data.Dimensions.data.dimensionLength,
					"width": this.consolidation.data.Dimensions.data.width,
					"height": this.consolidation.data.Dimensions.data.height,
					//"packaging_type": prepareCreationData.packaging_type,
				},
				//"insurance_amount": prepareCreationData.insurance_amount,


				"sender_contact_name": this.consolidation.data.sendType === this.consolidation.data.sendTypes.warehouse ?
					this.consolidation.data.recipientWarehouse?.letter_full_name :
					this.consolidation.data.User.user?.use_alternative_address === 1 ?
						this.consolidation.data.User.user?.user_alternative_contact ? this.consolidation.data.User.user.user_alternative_contact.name :
					this.consolidation.data.User.user.contacts[1].name :
						this.consolidation.data.User.user?.user_personal_contact ?
							this.consolidation.data.User.user.user_personal_contact.name : this.consolidation.data.User.user.contacts[0].name,

				"sender_phone_number": this.consolidation.data.sendType === this.consolidation.data.sendTypes.warehouse ?
					this.consolidation.data.recipientWarehouse?.sender_phone :
					this.consolidation.data.User.user?.use_alternative_address === 1 ?
						this.consolidation.data.User.user?.user_alternative_contact ? this.consolidation.data.User.user.user_alternative_contact.phone :
					this.consolidation.data.User.user.contacts[1].phone :
						this.consolidation.data.User.user?.user_personal_contact ?
							this.consolidation.data.User.user.user_personal_contact.phone : this.consolidation.data.User.user.contacts[0].phone,

				"recipient_address": this.consolidation.data.recipientWarehouse?.sender_address,
				"recipient_city": this.consolidation.data.recipientWarehouse?.sender_city,
				"recipient_country_id": this.consolidation.data.recipientWarehouse?.country_id,
				"recipient_zip_code": this.consolidation.data.recipientWarehouse?.sender_postcode,
				"delivery_date": this.consolidation.getShippedAt(),

			}

			return data
		},

		generateCreateLabelDataFromTable(consoItem, recipientWarehouse) {
			let products = []

			consoItem.product_data.forEach(item => {
				let proformKey = Object.keys(item?.proform)[0]
				let proform = item.proform[proformKey]

				products.push({
					name: proform.values[PROFORM_TYPE_NAMING][0]?.value?.translationStorage?.en?.name || 'product',
					count: proform.proformOptions.item_quantity,
					price: proform.proformOptions.item_price,
				})
			})

			if (consoItem.proform_data) {
				Object.keys(consoItem.proform_data).forEach(proformKey => {
					products.push({
						name: consoItem.proform_data[proformKey].values[PROFORM_TYPE_NAMING][0]?.value?.translationStorage?.en?.name || 'product',
						count: consoItem.proform_data[proformKey].proformOptions.item_quantity,
						price: consoItem.proform_data[proformKey].proformOptions.item_price,
					})
				})
			}

			let data = {
				"for_consolidation": true,
				"user_id": consoItem.user?.id,
				"warehouse_id" : consoItem.warehouse_id !== null ? consoItem.warehouse_id?.id : null,
				"sender":  consoItem.warehouse_id !== null ? {
					"country_code": consoItem.warehouse_id.country?.code_iso_2,
					"post_code": consoItem.warehouse_id?.sender_postcode
				} : consoItem.user?.use_alternative_address === 1 ?
					{
						"country_code": consoItem.user?.user_alternative_contact ? consoItem.user.user_alternative_contact.country.code_iso_2 :
							consoItem.user.contacts[1].country.code_iso_2,
						"city": consoItem.user?.user_alternative_contact ? consoItem.user.user_alternative_contact.city :
							consoItem.user.contacts[1].city,
						"post_code": consoItem.user?.user_alternative_contact ? consoItem.user.user_alternative_contact.zip :
							consoItem.user.contacts[1].zip
					} :
					{
						"country_code": consoItem.user?.user_personal_contact ? consoItem.user.user_personal_contact.country.code_iso_2 :
							consoItem.user.contacts[0].country.code_iso_2,
						"city": consoItem.user?.user_personal_contact ? consoItem.user.user_personal_contact.city :
							consoItem.user.contacts[0].city,
						"post_code": consoItem.user?.user_personal_contact ? consoItem.user.user_personal_contact.zip :
							consoItem.user.contacts[0].zip
					},
				"recipient": {
					"country_code": recipientWarehouse?.country?.code_iso_2,
					"post_code": recipientWarehouse?.sender_postcode
				},
				"products": products,
				"proform_packaging": {
					"weight": consoItem.dimensions.weight,
					"length": consoItem.dimensions.length,
					"width": consoItem.dimensions.width,
					"height": consoItem.dimensions.height,
					//"packaging_type": prepareCreationData.packaging_type,
				},
				//"insurance_amount": prepareCreationData.insurance_amount,


				"sender_contact_name": consoItem.warehouse_id !== null ?
					consoItem.warehouse_id?.letter_full_name :
					consoItem.user?.use_alternative_address === 1 ?
						consoItem.user?.user_alternative_contact ? consoItem.user.user_alternative_contact.name :
							consoItem.user.contacts[1].name :
						consoItem.user?.user_personal_contact ?
							consoItem.user.user_personal_contact.name : consoItem.user.contacts[0].name,

				"sender_phone_number": consoItem.warehouse_id !== null ?
					consoItem.warehouse_id?.sender_phone :
					consoItem.user?.use_alternative_address === 1 ?
						consoItem.user?.user_alternative_contact ? consoItem.user.user_alternative_contact.phone :
							consoItem.user.contacts[1].phone :
						consoItem.user?.user_personal_contact ?
							consoItem.user.user_personal_contact.phone : consoItem.user.contacts[0].phone,

				"recipient_address": recipientWarehouse?.sender_address,
				"recipient_city": recipientWarehouse?.sender_city,
				"recipient_country_id": recipientWarehouse?.country_id,
				"recipient_zip_code": recipientWarehouse?.sender_postcode,
				"delivery_date": this.$moment(consoItem?.shipped_at).format('YYYY-MM-DD'),
			}

			return data
		},

		getLabelPriceFromCard() {
			let data = this.generateCreateLabelDataFromCard()
			this.getCostLabel(data)
		},

		getCostLabel(data, destinationId = false) {

			let deliveryServiceType = ''
			let destination = null

			if(destinationId) {
				destination = Number(destinationId)
			} else {
				destination = Number(this.consolidation.getConsolidationDestination().id)
			}

			if(Number(CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id) === destination) {
				deliveryServiceType = 'fedex'
			}

			if(Number(CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id) === destination) {
				deliveryServiceType = 'dhl'
			}

			this.$store.dispatch('getShippingCost', {data: data, type: deliveryServiceType}).then((response) => {
				if (this._.has(response, 'data') &&
					response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

					this.labelPrice = response.data.data
					console.log(this.labelPrice);
					let totalProdPrice = 0
					data.products.forEach(item => {
						totalProdPrice += +item.price * +item.count
					})
					if (totalProdPrice > 800 && this?.blockBuyLabelConsolidation === false) {
						//block buy label btn if price is too high
						this.blockBuyLabelConsolidation = true
					}
					if (totalProdPrice <= 800 && this?.blockBuyLabelConsolidation === true) {
						//unblock btn if changed
						this.blockBuyLabelConsolidation = false
					}

				} else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
					let errors = response.response.data.errors

					if(Object.keys(errors).length > 0){
						this.openNotify('error', {txtServer: this._.first(errors[this._.first(Object.keys(errors))]) })
					}
				} else {
					this.openNotify('error', 'common_notificationUndefinedError')
				}
			})
		},

		makeLabelHandler(id) {
			return new Promise((resolve) => {
				console.log(345345);
				this.$store.dispatch('consolidationCreateLabel', {'consolidation_id': id}).then(response => {
					if (this._.has(response, 'data') &&
						response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

						let responseData = response.data.data
						console.log(responseData);
						if(responseData.status === 'not_payed'){
							this.openNotify('error', {txtServer: responseData.message})
							this.authorizePaySum = responseData.total_amount
							this.authorizeInvoiceId = responseData.invoice_id

							this.authorizePayData = {
								userBalance: responseData.userBalance,
								orderAmount: responseData.order_amount,
								paySystemCommission: responseData.payment_system_commission,
							}
							console.log(this.authorizePayData);
							console.log(345345);

							if(this._.has(this.currentPermissions, this.PERMISSIONS.CUSTOMER_PAYMENT_BRAINTREE)) {
								this.braintreePaymentPopup = true
							} else {
								this.changeAuthorizePaymentPopup(true)
							}

							resolve({statusPaid: 'not_payed'})
							return
						}

						resolve('complete')

					} else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
						let errors = response.response.data.errors

						if(this._.has(errors, 'api_errors')){
							this.openNotify('error', {txtServer: errors['api_errors'][0]})
							resolve('error')
							return
						}

						if(Object.keys(errors).length > 0){
							this.openNotify('error', {txtServer: this._.first(errors[this._.first(Object.keys(errors))]) })
						}

						resolve({statusError: 'error'})
					} else {
						this.openNotify('error', 'common_notificationUndefinedError')
						resolve('error')
					}
				})
			})

		},

		getFileUUID(files, fileExt, fileType) {
			let foundItem = files.find(item => {
				return item.extension === fileExt && item.type === fileType
			})

			return foundItem?.uuid || ''
		},

		getLabelPriceFromTable(consoItem, recipientWarehouse) {
			let data = this.generateCreateLabelDataFromTable(consoItem, recipientWarehouse)

			this.getCostLabel(data, consoItem?.consolidation_destination_id)
		},
	}
}
