// const _ = require('lodash');


import {checkValid} from "./functions/ModelsValidate";

export function ProductCells(options, cellSelected, storageCount, cellCount) {
  this.checkValid = checkValid

  this.data = {
    cellOptions: options ? options : [],
    cellSelected: cellSelected ? cellSelected : null,
    cellCount: cellCount ? cellCount : 0,
    storageCount: storageCount ? storageCount : 0,

    cellType: '',
  }

  this.validation = {
    cellSelected: false,
    cellCount: false,
    storageCount: false,
  }

  this.validationTranslate = {
    cellSelected: '',
    cellCount: '',
    storageCount: '',
  }

  this.validationTxt = {
    cellSelected: false,
    cellCount: false,
    storageCount: false,
  }

  /**
   * Getters
   */

  this.getCellOptions = () => {
    return this.data.cellOptions
  }
  this.getCellSelected = () => {
    return this.data.cellSelected
  }
  this.getCellCount = () => {
    return this.data.cellCount
  }
  this.getStorageCount = () => {
    return this.data.storageCount
  }

  /**
   * Setters
   */

  this.setCellOptions = function (val) {
    this.data.cellOptions = val
  }
  this.setCellSelected = function (val) {
    this.data.cellSelected = val
  }
  this.setCellCount = function (val) {
    this.data.cellCount = val
  }
  this.setStorageCount = function (val) {
    this.data.storageCount = val
  }
}

/**
 * GLOBAL GETTERS
 */

/**
 * GLOBAL SETTERS
 */

/**
 * FUNCTIONS
 */


/**
 * VALIDATIONS
 */

ProductCells.prototype.checkRatioCellCountAndStorage = function () {
  return parseInt(this.data.cellCount) <= parseInt(this.data.storageCount)
}


ProductCells.prototype.validationSelected = function () {
  if(!this.data.cellSelected?.id && parseInt(this.data.cellCount) === 0){
    this.validation = {
      cellSelected: false,
      cellCount: false,
    }
    return true
  }

  let validationItems = {
    cellCount: this.data.cellCount,
    cellSelected: this.data.cellSelected,
  }


  let validationOptions = {
    cellCount: {type: ['not-zero', 'empty']},
    cellSelected: {type: ['empty']},
  }

  return this.checkValid(validationItems, validationOptions)
}

ProductCells.prototype.validationCount = function () {
  if(!this.data.cellSelected?.id && parseInt(this.data.cellCount) === 0){
    this.validation = {
      cellCount: false,
    }
    return true
  }

  let validationItems = {
    cellCount: this.data.cellCount,
  }


  let validationOptions = {
    cellCount: {type: ['not-zero', 'empty']},
  }

  return this.checkValid(validationItems, validationOptions)
}

/**
 * PREPARE DATA
 */


